#profilePage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .profile-container-top {
    width: 800px;
    height: 20vh;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    flex-direction: column;
    justify-content: center;
    h3 {
      color: #009944;
      font-size: 30px;
      font-weight: 600;
    }
    p {
      font-size: 18px;
      padding: 10px;
    }
  }
  .profile-container-center {
    width: 800px;
    height: 60vh;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    flex-direction: column;
    justify-content: center;
    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 150px;
        height: 180px;
      }
      h4 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
  .profile-container-bottom {
    width: 800px;
    height: 20vh;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
  }
  .button-text-call {
    display: none;
  }
  @media only screen and (max-width: 850px) {
    .profile-container-top {
      width: 100% !important;
    }
    .profile-container-center {
      width: 100% !important;
    }
    .profile-container-bottom {
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 750px) {
    .button-call {
      display: none;
    }
    .button-text-call {
      display: flex;
    }
  }
  @media only screen and (max-width: 375px) {
    .profile-container-top {
      p {
        font-size: 16px;
        padding: 5px;
      }
    }
  }
}
