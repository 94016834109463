/** @format */

#meetingConfirmPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: bold;
    font-size: 30px;
    padding: 10px;
  }
  .button-test {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    button {
      width: 200px;
      height: 60px;
      outline: unset;
      background-color: #009943;
      border: 0 !important;
      border-radius: 40px;
      color: white;
    }
  }
  .button-text-call {
    display: none;
  }
  .button-call {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
    button {
      width: 200px;
      height: 60px;
      outline: unset;
      background-color: #009943;
      border: 0 !important;
      border-radius: 40px;
      color: white;
    }
  }
  .meeting-confirm-container {
    display: flex;
    justify-content: center;
    .meeting-content {
      width: 600px;
      margin-top: 30px;
      .meeting-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        height: 60px;
        border-bottom: 1px solid #c0c0c0;
        .meeting-radio-button {
          display: flex;
          flex-direction: row;
          .radio-check-checkSpeaker {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .loading-video-call {
    position: absolute;
    top: 47%;
    left: 48.5%;
  }
  @media only screen and (min-width: 750px) {
    .button-call {
      display: none;
    }
    .button-text-call {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
      text-decoration: underline;
      button {
        width: 200px;
        height: 60px;
        outline: unset;
        background-color: #009943;
        border: 0 !important;
        border-radius: 40px;
        color: white;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 26px;
    }
  }
}
