/** @format */

#noRemoteVideoView {
  width: 100%;
  height: 100%;
  // background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  .view-container {
    width: 150px;
    height: 150px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #009943;
    color: white;
    font-size: 40px;
  }
}
