#notifySecond {
  position: absolute;
  z-index: 10000;
  top: 80px;
  .notify-container {
    background-color: #e95d44;
    width: 360px;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    border-radius: 30px;
    color: #fff;
  }
}
