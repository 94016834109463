/** @format */

#headerLayout {
  width: 100%;
  border-bottom: solid 1px #cccccc;
  background-color: #fff;
  .container-meeting {
    display: flex;
    justify-content: center;
    align-items: center;
    .zwei-meeting {
      display: flex;
      height: 60px;
      width: 1080px !important;
      padding: 0 40px !important;
      justify-content: space-between;
      align-items: center;
    }
  }
}
